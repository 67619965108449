import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useEffect, useState } from "react";
import BookingApi from "../../API/Apis/BookingApi";
import { useNavigate } from "react-router-dom";
import BookingDetalisListSideNavigation from "../../components/Layouts/ReservationsCard/BookingDetalisListSideNavigation";
import { AppStandardButton } from "../../components/UI/AppButtons";
import NotAuthorisedPage from "../notAuthorisedPage";
import { t } from "i18next";
import DefaultPage from "../../components/Layouts/DefaultPage";

export default function CustomerDetailsList() {
  const navigateTo = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const [showCustomerDetails, setShowCustomerDetails] = useState(true);
  const [totalCustomers, setTotalCustomers] = useState(0);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // .css-y8ay40-MuiTableCell-root

  const [data, setCustomerDetails] = useState([]);

  // const Clicked = (customerId) => {
  //   localStorage.setItem("customerId", customerId);
  //   navigateTo("/updateCustomer");
  // };

  useEffect(() => {
    BookingApi.getAppSetting().then((e) => {
      setShowCustomerDetails(e.reservedArea);
    });
  }, []);

  useEffect(() => {
    const id: any = localStorage.getItem("userID");
    const offset = page * rowsPerPage;
    BookingApi.getCustomerDeatilsByUserid(id, offset, rowsPerPage).then((e) => {
      setTotalCustomers(e.data.total);
      setCustomerDetails(e.data.customerList);
    });
  }, [page, rowsPerPage]);

  return (
    <div>
      {showCustomerDetails ? (
        <div className="flex">
          <div className="!w-780">
            <BookingDetalisListSideNavigation />
          </div>
          <div className="w-[72%] rounded-sm pt-12">
            <div className="text-[#333] px-8 max-w-screen-xl m-auto">
              <div className={"flex justify-between"}>
                <p className="text-[24px] font-medium leading-snug">
                  {t("Customer Details")}
                </p>
                <AppStandardButton
                  href="/"
                  className={"flex mr-1"}
                  variant="contained"
                  style={{
                    width: "150px",
                    backgroundColor: "#ff0013",
                    borderRadius: "10px",
                  }}
                >
                  <span className="normal-case">Book a Car</span>
                </AppStandardButton>
              </div>
              <p className="text-[#838181] text-[16px]">
                {t("Here is your customer details")}
              </p>
            </div>
            {data.length === 0 ? (
              <DefaultPage
                message={
                  "You have not created any booking yet .To create new Booking Please click on the Book a car button"
                }
              ></DefaultPage>
            ) : (
              <>
                <TableContainer className="!shadow-grey-400 !rounded-2xl !border-solid !border-3 p-4">
                  <Table
                    className="!shadow-2xl rounded-2xl !border-solid !border-2 !border-grey-400"
                    aria-label="simple table"
                    stickyHeader
                  >
                    <TableHead className="!bg-[#ff0013] rounded-2xl">
                      <TableRow className="!bg-[#ff0013]">
                        <TableCell className="!bg-[#ff0013] !text-[#fff] !text-[15px] rounded-tl-2xl ">
                          {t("Full name")}
                        </TableCell>
                        <TableCell className="!bg-[#ff0013] !text-[#fff] !text-[15px]">
                          {t("Email")}
                        </TableCell>
                        <TableCell className="!bg-[#ff0013] !text-[#fff] !text-[15px]">
                          {t("Phone")}
                        </TableCell>
                        <TableCell className="!bg-[#ff0013] !text-[#fff] !text-[15px] rounded-tr-2xl">
                          {t("Company Name")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.map((e: any, i) => (
                        <TableRow key={i}>
                          <TableCell
                            className="!text-[12px] rounded-bl-2xl"
                            component="th"
                            scope="e"
                          >
                            <ul>
                              <li>
                                {e.name || ""} {e.surname}
                              </li>
                            </ul>
                          </TableCell>
                          <TableCell className="!text-[12px]">
                            {e.email || ""}
                          </TableCell>
                          <TableCell className="!text-[12px]">
                            {e.phoneNumb1 || ""}
                          </TableCell>
                          <TableCell className="!text-[12px] rounded-br-2xl">
                            {e.isPhysicalPerson == "true"
                              ? ""
                              : e.companyName || ""}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[8]}
                  component="div"
                  count={totalCustomers}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            )}
          </div>
        </div>
      ) : (
        <NotAuthorisedPage />
      )}
    </div>
  );
}
